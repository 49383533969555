import React from 'react';
import { CTA, IGatsbyImage, ImageType } from 'utils/types';

import { DigitalResultsSection } from '../../../components/DigitalResultsSection';

type ContentSection = {
  title: string;
  headline: string;
  image: IGatsbyImage;
};

export interface ContentAndParallaxContainerProps {
  content: {
    slug: string;
    title: string;
    headline: string;
    description?: {
      description: string;
    };
    subHeadline: string;
    subHeadlineDescription?: {
      subHeadlineDescription: string;
    };
    desktopImages: Array<ImageType>;
    mobileImages: Array<ImageType>;
    mainCta?: CTA;
    listOfContentWithImages: ContentSection[];
    backgroundColor?: 'Primary' | 'Secondary';
  };
}

export const ContentAndParallaxContainer: React.FC<ContentAndParallaxContainerProps> =
  ({ content }) => {
    if (!content) {
      return null;
    }
    const {
      slug,
      title,
      headline,
      description,
      subHeadline,
      subHeadlineDescription,
      desktopImages,
      mobileImages,
      mainCta,
      listOfContentWithImages = [],
      backgroundColor,
    } = content;

    const listOfContent =
      listOfContentWithImages &&
      listOfContentWithImages.map((content: any) => ({
        title: content.title,
        description: content.headline,
        image: content.image.gatsbyImageData,
        imageAlt: content.image.description || '',
      }));

    const props = {
      uid: slug,
      title,
      headline,
      description: description?.description,
      subHeadline,
      subHeadlineDescription: subHeadlineDescription?.subHeadlineDescription,
      listOfContent,
      mainCta,
      desktopImages: desktopImages.map((image) => ({
        gatsbyImageData: image.gatsbyImageData,
        description: image.description,
      })),
      mobileImages: mobileImages.map((image) => ({
        gatsbyImageData: image.gatsbyImageData,
        description: image.description,
      })),
      backgroundColor,
    };

    return <DigitalResultsSection {...props} />;
  };

export default ContentAndParallaxContainer;
